import cookies from './cookies';
import { api, apiApplicantsV3, apiV2 } from 'services/api';
import { Vacancy, VacancySalary, VacancySimple } from 'types/vacancy';
import { numberToCurrency, getSlugFromText, removeEmpty } from './format';
import { KEY_USER_STORAGE } from './constants';
import axios from 'axios';

interface ApplyVacancyInJobsProps {
  vacancy: Vacancy;
  redirect: string;
  source: string | string[];
  recommendation?: {
    name: string;
    email: string;
  };
  goToProfiler?: boolean;
}

export const applyVacancyInJobs = async ({
  vacancy,
  redirect,
  source,
  recommendation,
  goToProfiler,
}: ApplyVacancyInJobsProps) => {
  const windowName = 'jobs-vacancy-' + String(vacancy.id);
  const dataApply = {
    applyingVacancy: true,
    vacancy: {
      id: vacancy.id,
      title: vacancy.title,
      source: source || 'jobs',
    },
    company: {
      slug: vacancy.slug,
      name: vacancy.companyName,
    },
    recommendation: recommendation,
  };

  window.name = windowName;
  cookies.set('window_name', windowName, { expires: null });

  cookies.set('redirect_url', redirect, { expires: null });
  cookies.set(windowName, JSON.stringify(dataApply), { expires: null });

  if (!!goToProfiler) {
    location.href = `${process.env.CANDIDATE_HOST_V3}/pre-profiler`;
  } else {
    location.href = `${process.env.CANDIDATE_HOST_V3}/curriculo`;
  }

  const userStorage = JSON.parse(sessionStorage.getItem(KEY_USER_STORAGE));

  if (userStorage?.vacancies) {
    sessionStorage.setItem(
      KEY_USER_STORAGE,
      JSON.stringify({
        ...userStorage,
        vacancies: {
          applications: null,
          favorites: userStorage.vacancies.favorites,
        },
      })
    );
  }
};

export const hasRequirementFields = async ({ vacancy, user }) => {
  const data = {
    id: vacancy?.id,
    email: user?.email,
  };

  try {
    const response = await api.post('/home/vacancy/enabledtoapply', data);
    const hasReqFields = response.data.length > 0;

    return hasReqFields;
  } catch (error) {
    return error;
  }
};

export const getAppliedVacancies = async (isApplicantsV3 = false): Promise<number[]> => {
  const userStorage = JSON.parse(sessionStorage.getItem(KEY_USER_STORAGE));

  if (userStorage?.vacancies?.applications) {
    return userStorage.vacancies.applications;
  }

  try {
    const apiAplicants = isApplicantsV3 ? apiApplicantsV3 : apiV2;
    const response = await apiAplicants.get('/applicant/my-applications');
    return response.data?.data?.map(item => item.id);
  } catch (err) {
    console.log(err);
  }

  return [];
};

export const getVacancySalary = (salary: VacancySalary): string => {
  if (salary) {
    const { finalRange, initialRange, type } = salary;

    if (type === 'simple' && finalRange) {
      return numberToCurrency(finalRange);
    } else if (type === 'range' && initialRange) {
      const isEqual = numberToCurrency(initialRange) === numberToCurrency(finalRange);

      if (isEqual) {
        return numberToCurrency(initialRange);
      }

      return `${numberToCurrency(initialRange)} - ${numberToCurrency(finalRange)}`;
    }
  }

  return 'Não informado';
};

export const getFavoriteVacancies = async (userId: number): Promise<number[]> => {
  try {
    const response = await api.get(`/portal-bookmarks/${userId}`);
    return response.data.data;
  } catch (err) {
    console.log(err);
  }
  return [];
};

interface RemoveDataProps {
  vacancyId: number;
  email: string;
}

export const removeApplied = async (data: RemoveDataProps): Promise<boolean> => {
  if (data.email === '' || !data.vacancyId) {
    return false;
  }

  try {
    await api.delete('candidature/remove', { data });
    return true;
  } catch (err) {
    console.log(err);
  }

  return false;
};

export const getVacancyUrl = (vacancy: Vacancy | VacancySimple): string => {
  if (vacancy.slug) {
    const vacancySlug = vacancy.title ? getSlugFromText(vacancy.title) : '';
    return `/vaga/${vacancy.id}-${vacancySlug}`;
  }

  return `${process.env.CANDIDATE_HOST}/${vacancy.id}`;
};

export const getUserVacancies = async () => {
  let vacancies = { favorites: [], applied: [], closed: [] };

  const token = cookies.get('token');
  const userId = cookies.get('userId');

  const apiVacancies = axios.create({
    baseURL: process.env.API_HOST,
    headers: {
      authorization: token,
    },
  });

  try {
    const response = await apiVacancies.get(`/vacancy/bookmarks?userId=${userId}`);
    vacancies.favorites = response.data.data.data;
  } catch (err) {
    console.log('\n favorites:', err.response.status, err.response.statusText, err.response.data?.message);
  }

  try {
    const response = await apiVacancies.get('/vacancy/candidature/closed');
    vacancies.closed = response.data.data.data;
  } catch (err) {
    console.log('\n closed:', err.response.status, err.response.statusText, err.response.data?.message);
  }

  try {
    const response = await apiVacancies.get('/vacancy/candidature/applied');
    vacancies.applied = response.data.data;
  } catch (err) {
    console.log('\n applied:', err.response.status, err.response.statusText, err.response.data?.message);
  }

  return vacancies;
};

export const buildGoogleForJobsData = (vacancy: Record<string, unknown>): object | string => {
  const vacancyObj = Object(vacancy);

  if (!vacancy.isGoogleForJobsEnabled) {
    return '';
  }

  const googleForJobsSchema = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: vacancyObj.title,
    description: vacancyObj.description,
    datePosted: vacancyObj.createdAt,
    identifier: {
      '@type': 'PropertyValue',
      name: 'Portal de Vagas Sólides',
      value: 'vagas.solides.com.br',
    },
    hiringOrganization: {
      '@type': 'Organization',
      name: vacancyObj.companyName,
      sameAs: vacancyObj.redirectLink,
      logo: vacancyObj.companyLogo,
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: vacancyObj.city?.name,
        addressRegion: vacancyObj.state?.code,
        addressCountry: vacancyObj.state?.country || 'BR',
        streetAddress: vacancyObj.city?.streetAddress || vacancyObj.city?.name || '',
      },
    },
    baseSalary:
      vacancyObj.salary?.initialRange &&
      vacancyObj.salary?.finalRange &&
      vacancyObj.salary?.showRangeToApplicant
        ? {
            '@type': 'MonetaryAmount',
            currency: vacancyObj.salary.currency || 'BRL',
            value: {
              '@type': 'QuantitativeValue',
              minValue: vacancyObj.salary.initialRange,
              maxValue: vacancyObj.salary.finalRange,
              unitText: vacancyObj.salary.frequency || 'MONTH',
            },
          }
        : '',
    employmentType:
      vacancyObj?.showModality && vacancyObj.recruitmentContractType?.length
        ? vacancyObj.recruitmentContractType
            .map((contract: Record<string, unknown>) => contract.name)
            .toString()
        : '',
    jobBenefits: vacancyObj.benefits?.length
      ? vacancyObj.benefits.map((benefit: Record<string, unknown>) => benefit.name).toString()
      : '',
    educationRequirements: vacancyObj.education?.length
      ? vacancyObj.education.map((education: Record<string, unknown>) => education.name).toString()
      : '',
    experienceRequirements: vacancyObj.hardSkills?.length
      ? vacancyObj.hardSkills.map((skill: Record<string, unknown>) => skill.name).toString()
      : '',
  };

  const googleForJobsData = removeEmpty(Object(googleForJobsSchema));

  return googleForJobsData;
};
