import useSWR from 'swr';
import { api } from 'services/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetch<Data = any, Error = any>(
  url: string | object,
  options: object = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnMount: true,
  }
) {
  const { data, error, isLoading } = useSWR<Data, Error>(
    url,
    async url => {
      const response = await api.get(url);
      return response.data;
    },
    options
  );

  return { data, error, isLoading };
}
