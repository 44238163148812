import Image from 'next/image';
import { GallerySkeleton } from './Skeleton';

const MAX_WIDTH = 2685;
const MAX_HEIGHT = 800;

export const GallerySection = ({ data, isLoading, className = '' }) => {
  const renderAnotherPictures = data?.slice(1, data.length).map((item: string, index: number) => {
    return (
      <Image
        src={item}
        key={index}
        alt="Company photo"
        width={MAX_WIDTH / (data.length - 1)}
        height={MAX_HEIGHT / (data.length - 1)}
        objectFit="cover"
      />
    );
  });

  return (
    <section className={className}>
      {isLoading ? (
        <GallerySkeleton />
      ) : (
        <div data-testid="gallery-photos" className="flex flex-col px-5 md:flex-row md:px-0 gap-3">
          {data?.length > 0 && (
            <Image
              alt="Company photo"
              src={data[0]}
              width={MAX_WIDTH}
              height={MAX_HEIGHT + 10}
              objectFit="cover"
            />
          )}

          {data?.length > 1 && (
            <div
              className={`grid grid-rows-${data.length === 2 ? 1 : 2}
            min-h-[250px] lg:grid-rows-${data.length === 2 ? 1 : 2} gap-3 lg:min-h-[500px]`}
            >
              {renderAnotherPictures}
            </div>
          )}
        </div>
      )}
    </section>
  );
};
