import Link from 'next/link';
import Image from 'next/image';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Icon, Text } from '@solides/management-util-ui';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { AvatarMenuProps, NavigationItem } from './types';
import cookies from 'utils/cookies';

const classNavItem =
  'rounded-lg hover:cursor-pointer hover:bg-gray-200 focus:bg-gray-200 houver:outline-none focus:outline-gray-300 transition duration-300 ease-in-out';

const NavItem = ({ url = '', icon, name, variant = 'link', onClick = () => {} }: NavigationItem) => {
  const colorCompany = localStorage.getItem('colorCompany') || '';

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Enter') {
      if (variant === 'link') {
        location.href = url;
      } else {
        onClick();
      }
    }
  };

  if (variant === 'link' && url === '') {
    return null;
  }

  return (
    <DropdownMenu.Item className={classNavItem} onKeyDown={onKeyDown}>
      {variant === 'link' ? (
        <Link href={url}>
          <a className="w-full flex items-center gap-x-4 p-3 rounded-lg">
            <Icon face={icon} className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`} />
            <Text variant="body2">{name}</Text>
          </a>
        </Link>
      ) : (
        <button type="button" className="w-full flex items-center gap-x-4 p-3 rounded-lg" onClick={onClick}>
          <Icon
            face={icon}
            className={`${colorCompany ? `text-[${colorCompany}]` : 'text-primary'}`}
            variant="outlined"
          />
          <Text variant="body2">{name}</Text>
        </button>
      )}
    </DropdownMenu.Item>
  );
};

export const AvatarMenu = ({ user, navigation, handleSignOut }: AvatarMenuProps) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const colorCompany = cookies.get('colorCompany') || '';
  const colorTextCompany = cookies.get('colorTextCompany') || '';

  useEffect(() => {
    setIsOpen(false);
  }, [router.pathname]);

  return (
    <DropdownMenu.Root onOpenChange={setIsOpen} open={isOpen} modal={false}>
      <DropdownMenu.Trigger
        data-cy="trigger-avatar-menu"
        data-testid="trigger-avatar-menu"
        className={`w-9 h-9 md:w-11 md:h-11 relative flex justify-center items-center rounded-full overflow-hidden ${
          `bg-[${colorCompany}]` || 'bg-primary'
        } flex`}
      >
        {user.avatar_url ? (
          <Image
            src={user.avatar_url}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="Foto de avatar do usuário"
          />
        ) : (
          <Icon face="person_outline" className={`text-[${colorTextCompany}] m-auto"`} />
        )}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          style={{ zIndex: 20 }}
          align="end"
          sideOffset={5}
          className="flex flex-col items-stretch bg-white rounded-lg pb-2 w-56 shadow-2xl shadow-gray-500/40 z-30"
        >
          <DropdownMenu.Group className="pl-5 py-4">
            <DropdownMenu.Label>
              <Text variant="subtitle2" data-cy="user-name">
                {user.name || 'Candidato(a)'}
              </Text>
            </DropdownMenu.Label>
            <DropdownMenu.Label>
              <Text variant="caption" className="text-gray-600 break-words pr-3.5" data-cy="user-email">
                {user.email}
              </Text>
            </DropdownMenu.Label>
          </DropdownMenu.Group>

          <DropdownMenu.DropdownMenuSeparator className="bg-[rgba(145,158,171,0.24)] h-[1px]" />

          <DropdownMenu.Group className="flex flex-col mt-2 px-2">
            {navigation.map((navItem, idx) => (
              <NavItem key={`${navItem.url}-${idx}`} {...navItem} />
            ))}

            <DropdownMenu.Item
              onClick={handleSignOut}
              data-cy="button-sign-out"
              className={`w-full flex items-center gap-x-4 p-3 ${classNavItem}`}
            >
              <Icon face="exit_to_app" className={`${`text-[${colorCompany}]` || 'text-primary'}`} />
              <Text variant="body2">Sair</Text>
            </DropdownMenu.Item>
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
