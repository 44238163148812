import Link from 'next/link';

import { Text } from '@solides/management-util-ui';
import { IconFacebook, IconInstagram, IconLinkedin, IconYoutube, IconSolides, IconTwitter } from 'assets';

import * as styles from './styles';

export const Footer = (): JSX.Element => {
  const renderHelpLinks = (
    <>
      <li>
        <a
          href="https://ajuda.solides.com.br/hc/pt-br"
          target="_blank"
          rel="noreferrer"
          data-cy="footer-link-helpdesk"
          aria-label="Link para a página de central de ajuda da sólides"
        >
          <Text variant="caption" color="gray.800">
            Central de ajuda
          </Text>
        </a>
      </li>
      <li>
        <Text variant="caption" color="gray.800">
          |
        </Text>
      </li>
      <li>
        <a
          href="https://ajuda.solides.com.br/hc/pt-br"
          target="_blank"
          rel="noreferrer"
          data-cy="footer-link-candidate-support"
          aria-label="Link para a página de suporte do candidato da sólides"
        >
          <Text variant="caption" color="gray.800">
            Suporte ao candidato
          </Text>
        </a>
      </li>
    </>
  );

  return (
    <footer className={styles.container}>
      <div className="w-full xl:px-0 max-w-6xl mx-auto flex flex-col gap-2 justify-center md:justify-between items-center">
        <div
          className="w-full grid justify-center items-center gap-y-5 gap-x-3 md:grid-cols-2"
          data-testid="footer-container-mobile"
        >
          <div className="flex justify-center md:justify-start">
            <Link href="/">
              <a className="w-28 md:w-44" aria-label="Link para a página inicial da empresa">
                <IconSolides className="m-auto md:m-0" />
              </a>
            </Link>
          </div>

          <div className="md:hidden flex justify-center flex-col gap-1">
            <ul className="flex gap-1 justify-center">{renderHelpLinks}</ul>
            <Text variant="caption" color="gray.800" className="clear-both">
              Sólides, tudo que o RH precisa em um só lugar.
            </Text>
          </div>

          <div className={styles.medias}>
            <a
              href="https://www.linkedin.com/company/solidestecnologia"
              target="_blank"
              rel="noreferrer"
              data-testid="footer-link-linkedin"
              data-cy="footer-link-linkedin"
              aria-label="Link para a página do linkedin da sólides"
            >
              <IconLinkedin color="#212B36" />
            </a>
            <a
              href="https://pt-br.facebook.com/solidestecnologia/"
              target="_blank"
              rel="noreferrer"
              data-testid="footer-link-facebook"
              data-cy="footer-link-facebook"
              aria-label="Link para a página do facebook da sólides"
            >
              <IconFacebook color="#212B36" />
            </a>
            <a
              href="https://www.instagram.com/solidestecnologia/"
              target="_blank"
              rel="noreferrer"
              data-testid="footer-link-instagram"
              data-cy="footer-link-instagram"
              aria-label="Link para a página do instagram da sólides"
            >
              <IconInstagram color="#212B36" />
            </a>
            <a
              href="https://www.youtube.com/c/SolidesTecnologia"
              target="_blank"
              rel="noreferrer"
              data-testid="footer-link-youtube"
              data-cy="footer-link-youtube"
              aria-label="Link para a página do youtube da sólides"
            >
              <IconYoutube color="#212B36" />
            </a>
            <a
              href="https://twitter.com/solides"
              target="_blank"
              rel="noreferrer"
              data-testid="footer-link-twitter"
              data-cy="footer-link-twitter"
              aria-label="Link para a página do twitter da sólides"
            >
              <IconTwitter color="#212B36" />
            </a>
          </div>
        </div>
        <div className="hidden w-full md:flex mt-3 items-center" data-testid="footer-container-desktop">
          <Text variant="caption" color="gray.800" className="flex-auto">
            Produto desenvolvido por: Sólides, tudo que o RH precisa em um só lugar.
          </Text>
          <ul className="flex gap-1">{renderHelpLinks}</ul>
        </div>
      </div>
    </footer>
  );
};
