/* eslint-disable react/destructuring-assignment */
import Link from 'next/link';
import { useMemo } from 'react';

import { getVacancyUrl } from 'utils/vacancy';
import { VacancySimple } from 'types/vacancy';
import { NotificationAction } from 'types/notification';

const Vacancy = (vacancy: VacancySimple) => {
  const urlVacancy = getVacancyUrl(vacancy);

  return (
    <strong>
      <Link href={urlVacancy}>
        <a>{vacancy.title}</a>
      </Link>
    </strong>
  );
};

const Company = (vacancy: VacancySimple) => (
  <strong>
    <Link href="/">
      <a>{vacancy.companyName}</a>
    </Link>
  </strong>
);

interface NotificationDescriptionProps {
  action: NotificationAction;
  vacancy: VacancySimple;
}

export const NotificationDescription = ({ action, vacancy }: NotificationDescriptionProps) => {
  const description = useMemo(() => {
    const showCompanyName = vacancy.companyName !== 'Empresa confidencial';

    if (action === 'resendEmail') {
      if (!showCompanyName) {
        return 'Uma empresa acaba de enviar um email para você. Acesse seu email e não perca nada.';
      }

      return (
        <>
          A empresa <Company {...vacancy} /> acaba de enviar um email para você. Acesse seu email e não perca
          nada.
        </>
      );
    }

    if (action === 'phaseAdvance') {
      return (
        <>
          Você acaba de avançar para a próxima etapa do processo seletivo para a posição de{' '}
          <Vacancy {...vacancy} />
          {showCompanyName && (
            <>
              , na empresa <Company {...vacancy} />
            </>
          )}
          !
        </>
      );
    }

    if (action === 'closeVacancy') {
      return (
        <>
          A vaga de <Vacancy {...vacancy} />{' '}
          {showCompanyName && (
            <>
              da <Company {...vacancy} />
            </>
          )}{' '}
          foi encerrada.
        </>
      );
    }

    return (
      <>
        A vaga de <Vacancy {...vacancy} />{' '}
        {showCompanyName && (
          <>
            da <Company {...vacancy} />
          </>
        )}{' '}
        passou por uma atualização.
      </>
    );
  }, [vacancy, action]);

  return <p className="text-caption mt-[2px]">{description}</p>;
};
