import { apiApplicantsV3, apiV2 } from 'services/api';
import { KEY_USER_STORAGE } from './constants';

export const getAppliedTalents = async (isApplicantsV3 = false): Promise<number[]> => {
  const userStorage = JSON.parse(sessionStorage.getItem(KEY_USER_STORAGE));

  if (userStorage?.talents?.applications) {
    return userStorage.talents.applications;
  }

  try {
    const api = isApplicantsV3 ? apiApplicantsV3 : apiV2;
    const response = await api.get('/applicant/my-talent-bases');
    return response?.data?.data?.map(item => item.company_id);
  } catch (err) {
    console.log(err);
  }

  return [];
};
