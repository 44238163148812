import { useRouter } from 'next/router';
import { Pagination } from '@solides/management-util-ui';

interface PagerProps {
  total: number;
  size?: number;
  current: number;
  className?: string;
}

export function Pager({ total, current, className = '', size = 0 }: PagerProps): JSX.Element {
  const router = useRouter();

  const changePage = (newPage: number) => {
    if (newPage !== current) {
      const { query, pathname } = router;
      query.page = newPage.toString();

      router.push({ pathname, query });
    }
  };

  return (
    <Pagination
      totalCount={total}
      pageSize={size}
      pageNumber={current}
      className={`"border-none ${className}`}
      onChangePagination={newPage => changePage(newPage)}
    />
  );
}
